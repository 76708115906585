import React from 'react';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page lxn2raga22o-editor_css' },
  logo: {
    className: 'header2-logo lxnly6i9nsi-editor_css',
    children: './assets/logo.png',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Content3_0',
        children: 'Возможности',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lxlqw2w34h',
        to: 'Feature6_0',
        children: 'Данные',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lxn3xhf67h',
        to: 'Pricing1_0',
        children: 'Тарифы',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lxlqvgunrtf',
        to: 'Content9_0',
        children: 'Подключение',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lxlqvsdxm0a',
        to: 'Banner5_0',
        children: 'Контакты',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lxlqvsdxm0a1',
        to: 'https://storeparking.ru/admin',
        children: 'Вход',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page lxlnak38mr6-editor_css' },
        textWrapper: {
          className: 'banner2-text-wrapper lxkrplgn3c8-editor_css',
        },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner2-title lxkrf5yunc-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                <span>PARKING</span>
                              </p>
                              <p>
                                <span>&amp;</span>
                              </p>
                              <p>
                                <span>STORE</span>
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'banner2-content lxkrr51rsif-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            <b>
                              Клубный сервис анализа коммерческих помещений от
                              застройщиков в сегменте машиномест и кладовых
                              помещений
                            </b>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        button: {
          className: 'banner2-button lxkrz7ldze6-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <p>Начать использование</p>
                </span>
              </span>
            </span>
          ),
          href: 'https://storeparking.ru/admin',
          type: 'primary',
        },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Преимущества сервиса</p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: './assets/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>АНАЛИТИКА</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Изучайте новые ЖК от застройщиков и количество доступных
                        предложений. Смотрите регионы присутствия и динамику
                        изменения цен по интересующим объектам. Используйте
                        калькулятор рентабельности для прогнозирования прибыли.
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                './assets/610c9d1292065ed105e85941_I_interview-approval.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>ПОИСК</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      Продвинутая система фильтров и представлений позволяет
                      легко найти интересующие объекты с подходящими
                      параметрами. Добавьте их в избранное, чтобы ничего не
                      упустить.
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                './assets/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>МОНИТОРИНГ</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Ежедневные обновления позволяют оперативно принимать
                          решения и оценивать риски. Регулярное добавление новых
                          застройщиков дает Вам полную картину рынка.
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature6-wrapper lxn2zo7mmi-editor_css',
  },
  OverPack: {
    className: 'home-page feature6 lxn2xf1s33-editor_css',
    playScale: 0.3,
  },
  Carousel: {
    className: 'feature6-content lxn2w5f5v0a-editor_css',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar lxnmouimqv9-editor_css' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text lxnmopnwfnj-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>НАШИ ДАННЫЕ</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper lxksy6io1yk-editor_css',
            name: 'child0',
            number: {
              className: 'feature6-number lxnmngrvdv-editor_css',
              unit: {
                className: 'feature6-unit lxktbwem0ar-editor_css',
                children: '万',
              },
              toText: true,
              children: '31',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>РЕГИОНЫ</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper lxksyh031c-editor_css',
            name: 'child1',
            number: {
              className: 'feature6-number lxnmoh5vde7-editor_css',
              unit: {
                className: 'feature6-unit lxktccbultc-editor_css',
                children: '亿',
              },
              toText: true,
              children: '389',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>КОМПЛЕКСЫ</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number lxnmolw9v5-editor_css',
              unit: {
                className: 'feature6-unit lxktceovvi-editor_css',
                children: '万',
              },
              toText: true,
              children: '56585',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>ОБЪЕКТЫ</p>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      './assets/Data-Wrangling-Service-2048x1667.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>УНИКАЛЬНАЯ АГРЕГАЦИЯ</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      Если Вам приходилось искать машиноместа или кладовки, то
                      Вы знаете, насколько это сложно в виду количества
                      застройщиков и различной структуры сайтов.
                      Индивидуальность каждого застройщика в этом случае сильно
                      сказывается.
                    </p>
                    <p>
                      <span>
                        <br />
                      </span>
                    </p>
                    <p>
                      <span>
                        Наш сервис является уникальным агрегатором предложений
                        от застройщиков России по данному сегменту коммерческой
                        недвижимости.
                      </span>
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper lxn2vxz7o-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      './assets/home-illustration.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <p>ДИНАМИКА ЦЕН И ПРЕДЛОЖЕНИЙ</p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        Возможность проследить историю изменения цены по
                        интересующим объектам чаще всего либо сильно затруднена,
                        либо просто отсутствует на сайтах застройщиков.&nbsp;
                      </p>
                      <p>
                        <span>
                          Появление новых объектов - важный факт, который сложно
                          отследить даже по одному ЖК, а если их много? А если
                          интересует больше чем один застройщик?&nbsp;
                        </span>
                      </p>
                      <p>
                        <span>Наш сервис решает все эти вопросы.</span>
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      './assets/reciept.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>БЕЗ ДОПОЛНИТЕЛЬНЫХ ТРАТ</p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          Мы не формируем добавленную стоимость и не собираем комиссионные сборы
          на цену, установленную застройщиком. На каждый объект мы предоставляем
          прямую ссылку, ведущую на сайт застройщика для возможности
          непосредственного контакта и бронирования.
        </p>
      </span>
    ),
  },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1 lxz5vxlcpnl-editor_css' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem lxz7vmwkkct-editor_css' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0 lxz5si8ri6-editor_css' },
        title: {
          className: 'banner1-title lxz67mjsaai-editor_css',
          children:
            './assets/slide_1.jpg',
        },
        content: {
          className: 'banner1-content lxz6otvt8g-editor_css',
          children: (
            <span>
              <p>УДОБНЫЙ ИНТЕРФЕЙС</p>
            </span>
          ),
        },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem lxz5y9iiu4c-editor_css' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1 lxz5xjlgk8d-editor_css' },
        title: {
          className: 'banner1-title lxz67u9bcdi-editor_css',
          children:
            './assets/slide_2.jpg',
        },
        content: {
          className: 'banner1-content lxz6pc9sp7-editor_css',
          children: (
            <span>
              <p>ИНСТРУМЕНТЫ АНАЛИЗА</p>
            </span>
          ),
        },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'banner1-title lxz682769q9-editor_css',
          children:
            './assets/slide_3.jpg',
        },
        content: {
          className: 'banner1-content lxz6pky17g-editor_css',
          children: (
            <span>
              <p>ГИБКИЙ ПОИСК</p>
            </span>
          ),
        },
      },
      {
        name: 'elem~lxz68bkr4pl',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg3' },
        title: {
          className: 'banner1-title lxz682769q9-editor_css',
          children:
            './assets/slide_4.jpg',
        },
        content: {
          className: 'banner1-content lxz6q32tdpl-editor_css',
          children: (
            <span>
              <span>
                <p>ДЕТАЛЬНАЯ ИНФОРМАЦИЯ</p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'elem~lxz68dfcs4',
        BannerElement: { className: 'banner-user-elem lxz6djx3dte-editor_css' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg4 lxz6dge144q-editor_css' },
        title: {
          className: 'banner1-title lxz682769q9-editor_css',
          children:
            './assets/slide_5.jpg',
        },
        content: {
          className: 'banner1-content lxz6qdfaaor-editor_css',
          children: (
            <span>
              <p>ФИНАНСОВЫЕ РАСЧЕТЫ</p>
            </span>
          ),
        },
      },
    ],
    type: ['across'],
    className: 'lxz5sk224oc-editor_css',
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Возможности сервиса</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              Функциональность постоянно расширяется на основе обратной связи
              пользователей
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              './assets/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>ИСТОРИЯ ЦЕН</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        Фиксируется по каждому объекту и всегда доступна для
                        анализа в удобном представлении
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              './assets/WBnVOjtIlGWbzyQivuyq.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>КАЛЬКУЛЯТОР</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Вы всегда можете рассчитать рентабельность и даже подобрать
                  подходящие объекты под ваши показатели
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              './assets/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>ИНТЕГРАЦИИ</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Сервис связан с ботом в Telegram, это дополнительный канал
                  поставки услуг сервиса, включая уведомления, работу с отчетами
                  и режим ассистента
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              './assets/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>УВЕДОМЛЕНИЯ</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Вы не пропустите важные изменения по вашим объектам, появление
                  нового комплекса или застройщика, открытие нового пула
                  предложений (а такое бывает часто)
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              './assets/YPMsLQuCEXtuEkmXTTdk.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>ОТЧЕТЫ</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    Постоянно дополняются. Помимо предустановленных шаблонов, мы
                    можем создавать отчеты по запросу с учетом доступной
                    информации
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              './assets/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>ИЗБРАННОЕ</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    Любой объект можно добавить в избранные для быстрого
                    доступа. Это также позволяет оперативно видеть изменения по
                    статусу и цене
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: {
    className: 'home-page-wrapper pricing1-wrapper lxn3yyh8jgj-editor_css',
  },
  page: { className: 'home-page pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>ТАРИФЫ</p>
          </span>
        ),
        className: 'pricing1-title-h1',
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <span>
                    <p>ДЕМО</p>
                  </span>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money lxn4c7u35ea-editor_css',
            children: (
              <span>
                <p>₽0</p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content lxn4efh6ws7-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            Бессрочно, есть ограничения:<br />&nbsp; -
                            интеграции с Telegram<br />&nbsp; - адреса
                            комплексов<br />&nbsp; - названия комплексов<br />&nbsp;
                            - ссылки по объектам<br />&nbsp; - режим "Избранное"
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: 'https://t.me/kladovki_bot',
                children: (
                  <span>
                    <p>Попробовать</p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block1',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>КЛУБНЫЙ</p>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money lxn44khlux-editor_css',
            children: (
              <span>
                <p>индивидуально</p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    Интеграция с Telegram<br />Отчеты<br />Режим "Избранное"<br />Полная
                    информация по объектам<br />Личные комментарии<br />IT-поддержка
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: 'https://t.me/kladovki_bot',
                children: (
                  <span>
                    <p>Вступить</p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>ПРЕМИУМ</p>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money lxn440w40l-editor_css',
            children: (
              <span>
                <p>в разработке</p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content lxn50q58vmr-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              Дополнительно к клубному:
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <div>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                &nbsp; - расширенные отчеты<br />&nbsp;
                                                - модуль уведомлений<br />&nbsp;
                                                - контроль обновлений комплексов<br />&nbsp;
                                                - отслеживание объектов&nbsp;<br />&nbsp;
                                                - доступ к объектам с торгов
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button lxn49o58a0e-editor_css',
                href: '#',
                children: (
                  <span>
                    <p>Получить</p>
                  </span>
                ),
              },
            },
          },
        },
      },
    ],
  },
};
export const Content90DataSource = {
  wrapper: {
    className: 'home-page-wrapper content9-wrapper lxn3zhwaszi-editor_css',
  },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children: './assets/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>КАК ПРИСОЕДИНИТЬСЯ</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block1',
        className: 'block-wrapper lxnlivamb7f-editor_css',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              './assets/eeee.png',
          },
          icon: {
            className: 'block-icon',
            children:
              './assets/qJnGrvjXPxdKETlVSrbe.svg',
          },
          name: {
            className: 'block-name lxn38426iek-editor_css',
            children: '姓名',
          },
          post: {
            className: 'block-post lxn387qf8jk-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Мы используем для связи Telegram, ссылка находится в конце
                      страницы, а также на сайте Сервиса.
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
          time: {
            className: 'block-time lxm0dsd6bpg-editor_css',
            children: '09:00 - 10:00',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>СВЯЖИТЕСЬ С НАМИ</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Мы используем для связи Telegram, ссылка находится в конце
                      страницы, а также на сайте Сервиса.
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block-wrapper lxnoys3xuck-editor_css',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              './assets/numitas_Umsetzung.png',
          },
          icon: {
            className: 'block-icon',
            children:
              './assets/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: {
            className: 'block-name lxn3bua20id-editor_css',
            children: '姓名',
          },
          post: {
            className: 'block-post lxn3bxq887j-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Расскажите о своем сообществе и объеме потенциальной
                      аудитории. Также будет интересна информация, какие регионы
                      Вам наиболее интересны. Мы, со своей стороны, представим
                      условия и порядок работы с сервисом.
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
          time: {
            className: 'block-time lxm0dzireu-editor_css',
            children: '09:00 - 10:00',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>СОГЛАСУЙТЕ УСЛОВИЯ</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Расскажите о своем сообществе и объеме потенциальной
                          аудитории. Также будет интересна информация, какие
                          регионы Вам наиболее интересны. Мы, со своей стороны,
                          представим условия и порядок работы с сервисом.
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper lxnp1k4xcr-editor_css',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper lxnowcbf4jl-editor_css' },
          img: {
            className: 'block-img',
            children: './assets/guarante.png',
          },
          icon: {
            className: 'block-icon',
            children:
              './assets/agOOBdKEIJlQhfeYhHJc.svg',
          },
          name: {
            className: 'block-name lxn3cbtz5g6-editor_css',
            children: '姓名',
          },
          post: {
            className: 'block-post lxn3ce4kon-editor_css',
            children: (
              <span>
                <span>
                  <p>
                    Доступ к сервису по умолчанию получают все действующие
                    участники Вашего клуба.
                  </p>
                  <p>
                    С момента присоединения сообщества, каждый участник может
                    самостоятельно пройти процедуру регистрации через нашего
                    бота в Telegram и получить полный доступ к сервису.
                  </p>
                </span>
              </span>
            ),
          },
          time: {
            className: 'block-time lxm0e3780sh-editor_css',
            children: '09:00 - 10:00',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>ПОЛУЧИТЕ ДОСТУП</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          <span>
                            Доступ к сервису по умолчанию получают все
                            действующие участники Вашего клуба.
                          </span>
                        </p>
                        <p>
                          <span>
                            С момента присоединения сообщества, каждый участник
                            может самостоятельно пройти процедуру регистрации
                            через нашего бота в Telegram и получить полный
                            доступ к сервису.
                          </span>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper lxlqaczt8o-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>НАЧНИТЕ!</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>PARKING &amp; STORE</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
              <p>
                Клубный сервис анализа коммерческих помещений от застройщиков в
                сегменте машиномест и кладовых помещений
              </p>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://storeparking.ru/admin',
          className: 'banner5-button lxm27w78gxe-editor_css',
          type: 'primary',
          children: (
            <span>
              <span>
                <span>
                  <p>Начать работу</p>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'button~lxm182pw2a',
        className: 'banner5-button-wrapper',
        children: {
          children: (
            <span>
              <span>
                <p>Мы в Telegram</p>
              </span>
            </span>
          ),
          href: 'https://t.me/kladovki_bot',
          type: 'default',
          className: 'banner5-button lxm187o3t1f-editor_css',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      './assets/A_-wAhRYnWQscAAAAAAAAAAABkARQnAQ.png',
  },
};
